// blog archive
.blog-archive {
  margin-bottom: 30px;
  // sort etc.
  .blog-archive-controls {
    display: block;  
    padding-left: 10px;
    margin-bottom: 30px;
    ul {
      margin: 5px 0 10px;
      li {
        a {
          color: $red;
        }
      }
    }
    @media (max-width: 991px) {
      text-align: center;
      > div {
        display: block;
        width: auto;
        margin: 0 auto;
        > div {
          display: inline-block;
          width: auto;
        }
      }
      ul {
        display: inline-block;
        width: auto;
        margin: 10px;
        float: left;
      }
    }
  }
}
  // post list
.blog-archive-posts, .blog-pinned-posts {
  background: #fdfafa;
  border: 1px solid #f4f4f4;
  display: block;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  justify-content: center;

  // post card
  .blog-post {
    display: block;
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
    max-width: 100%;
    max-width: 31%;
    max-width: 280px;
    margin: 10px;
    border: 2px solid #f4f4f4;
    background: #f9fafa;

    .card-header {
      position: relative;
      padding: 5px 10px;
      border-bottom: 2px solid $red;
      background: white;
      font-size: 16px;
    }

    .card-title {
      display: block;
      margin: 15px 0 10px;
      font-family: Cabin,sans-serif;
      font-weight: 700;
      font-size: 20px;
      color: $red;
      > a {
        color: $red;
      }
    }
    .card-subtitle {
      display: block;
      margin: 10px 0 0px;
      font-family: Cabin,sans-serif;
      font-weight: 700;
      color: $red;
      color: #322f2f;
      font-size: 16px;
      + sub {
        display: inline-block;
        margin-bottom: 5px;
        line-height: 16px;
        height: auto;
      }
      a {
        color: $red;
      }
    }

    .card-content {
      padding: 0;
      flex: 1;
      background: $white;
      .card-text {
        padding: 0 10px;
        margin-bottom: 20px;
        line-height: 1.4375;
      }
      .card-title {
        padding: 0 10px 10px;
      }
    }

    .card-image {
      height: auto;
      height: 200px;
      max-height: 200px;
      padding: 0;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      img {
        height: auto;
        max-height: 100%;
      }
    }

    .card-footer {
      padding: 10px 10px;
      border-top: 2px solid #f4f4f4;
    }

    @media (max-width: 991px) {
      flex-basis: 300px;
      max-width: 50%;
      max-width: 280px;
    }
    @media (max-width: 667px) {
      flex-basis: 100%;
      max-width: 100%;
      max-width: 280px;
    }

    // featured/pinned post 
    &.card-pinned {
      .pinned-post-pin {
        position: absolute;
        top: 15px;
        right: 10px;
        height: 30px;
        width: 30px;
      }
      .pinned-post-banner {
        position: relative;
        display: block;
        width: 100%;
        margin: 0;
        background: $red;
        color: $white;
        padding: 10px;
        text-align: center;
        width: calc(100% + 2px);
        margin-left: -1px;
        margin-top: -1px;
        h3 {
          display: block;
          color: $white;
          text-transform: uppercase;
          font-weight: normal;
          font-size: 16px;
        }
        small {
          display: block;
          color: $white;
          font-weight: normal;
          font-size: 14px;
          a {
            color: $white;
            text-decoration: underline;
          }
        }
      }
    }

  }
}

// blog pinned post
.blog-pinned-post {
  padding-bottom: 60px;
  @media (max-width: 991px) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #f4f4f4;
    .blog-post {
      display: block;
    }
  }
  .blog-pinned-post-blurb {
    display: block;  
    padding-left: 10px;
    padding-right: 25px;
    margin-bottom: 30px;
    ul {
      margin: 5px 0 10px;
    }
    a {
      color: $red;
    }
  }
  .blog-post {
    flex: 1 0 100%;
    .card-header {
      position: relative;
      text-align: center;
      .card-subtitle {
        font-size: 18px;
      }
    }
    .card-content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      // padding: 10px;
      .card-text-content {
        flex: 1 0 65%;
        padding: 10px;
      }
      .card-image {
        display: flex;
        flex: 0 0 35%;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        a {
          height: 100%;
          width: 100%;
          max-height: 100%;
          max-width: 100%;
        }
      }
      @media(max-width: 991px) {
        display: block;
        .card-image {
          min-height: 250px;
          max-height: 300px;
        }
      }
    }
  }
}


// blog featured content 
.blog-featured-content {
  padding-bottom: 20px;
  @media (max-width: 991px) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #f4f4f4;
  }
  // blurb
  .blog-featured-content-blurb {
    display: block;  
    padding-left: 10px;
    padding-right: 25px;
    margin-bottom: 30px;
    ul {
      margin: 5px 0 10px;
    }
    a {
      color: $red;
    }
    @media (max-width: 991px) {
      text-align: center;
    }
  }
  // carousel of content cards
  .blog-featured-content-carousel {
    background: #fdfafa;
    border: 1px solid #f4f4f4;
    #flexslider-blog {
      margin: 0 auto;
      padding: 15px 30px;
      box-shadow: none;
      max-height: 285px; // for fouc
      ul {
        // fall back for if slick fails
        li:not(.slick-slide) {
          display: inline-block;
          max-width: 24%;
          &:nth-child(n+5) {
            display: none;
          }
        }
      }
      .slick-slider {
        max-width: 100%;
        .slick-track {
          display: flex;
          align-items: center;
          .slick-slide {    
            margin: 5px;
            max-height: 300px; // max-height for blc
            overflow: hidden;
            padding: 0;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 100%;
                max-width: 200px; // max-width for blc
              }
            }
          }  
        }
      }
    }
  }
}

// combined
.blog-archive, .blog-featured-content, .blog-pinned-post {
  h2 {
    font-size: 22px;
    margin: 10px 0;
    color: $red;
  }
  h3 {
    display: inline-block;
    margin: 5px 0;
    font-family: Cabin,sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #322f2f;
  }
}

// tag cloud
.tag-cloud-link {
  display: inline;
  color: $red;
  transition: all .25s ease;
}

// blog post
.blog-post, .blog-pinned-post {
  .blog-post-title {
    min-height: 60px;
    display: flex;
    align-items: flex-end;
    > h1, h2 {
      font-size: 30px;
    }
  }
  .blog-post-meta {
    .blog-post-author {
      display: block;
      margin: 8px 0;
      color: $red;
      font-size: 18px;
    }
  }
  .blog-post-article {
    margin: 10px 0 30px;
  }
  .blog-post-image {
    img {
      display: block;
      margin: 0 auto;
      max-height: 300px;
    }

    ul {
      margin: 5px 0 10px;
      li {
        list-style: none;
        background-image: url(../img/blc-bullet.png);
        background-position: left top;
        background-repeat: no-repeat;
        padding: 2px 0 6px 23px;
        a {
          color: $red;
        }
      }
    }
    @media(max-width: 991px) {
      margin: 0 auto 20px;
    }
  }
}
