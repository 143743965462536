// pagination
.post-list-pagination, .posts-navigation {
  position: relative;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0;

  span, .nav-links {
    display: inline-block;
    margin: 0 10px;
    font-size: 14px;
    a {
      color: $black;
      &:hover {
        color: $red;
      }
    }
    &.current-paged {
      color: $red;
      font-size: 16px;
      border-top: 1px solid $red;
      border-bottom: 1px solid $red;
      padding: 8px 10px;
    }
  }
}
.posts-navigation {
  flex-direction: column;
  .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      margin: 0 10px;
    }
  }
}
