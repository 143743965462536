/*
Theme Name:     BLACKLETTER COMS
Theme URI:      
Description:    Blackletter Communications wordpress theme by Scott McMullan 
Author:         Scott McMullan
Author URI:     http://scott.mcmullan.pro 
Template:       twentysixteen
Version:        1.0.0
*/

@charset "utf-8";
/*========================================*/
/*   coded and designed by:
   _____ __________  ____________   __  ___________  _____  ____    __    ___    _   __
  / ___// ____/ __ \/_  __/_  __/  /  |/  / ____/  |/  / / / / /   / /   /   |  / | / /
  \__ \/ /   / / / / / /   / /    / /|_/ / /   / /|_/ / / / / /   / /   / /| | /  |/ / 
 ___/ / /___/ /_/ / / /   / /    / /  / / /___/ /  / / /_/ / /___/ /___/ ___ |/ /|  /  
/____/\____/\____/ /_/   /_/    /_/  /_/\____/_/  /_/\____/_____/_____/_/  |_/_/ |_/   
                                                                                       
/* scott.mcmullan.pro */

/*===============================================*/
/* 1.0 RESET STYLES 
/*====================*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-weight:inherit;
	font-style:inherit;
	font-size:100%;
	font-family:inherit;
	vertical-align:baseline;
}
body {
	line-height:1;
	color:black;
	background:white;
}
table {
	border-collapse:separate;
	border-spacing:0;
}
caption, th, td {
	text-align:left;
	font-weight:normal;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content:"";
}
blockquote, q {
	quotes:"" "";
}
/* HTML5 tags */
header, section, footer,
aside, nav, article, figure {
	display: block;
}

.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}

/**
 * For IE 6/7 only
 */
.cf {
    *zoom: 1;
}

/*===============================================*/
/* 2.0 BODY
/*====================*/



body{
	background-color:rgba(0,0,0,1.00);
}
header{
	position:relative;
}

.desk{
            display: none;
        }


/*===============================================*/
/* 3.0 TYPOGRAPHY
/*====================*/

@font-face {
font-family: 'Bebas Neue';
font-style: normal;
font-weight: normal;
src: local('Bebas Neue'), url('fonts/BebasNeue.woff') format('woff');
}

body{
	font-size:16px;
	font-family: 'Cabin', sans-serif;
	color:rgba(37,35,35,1.00);
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color:rgba(45,48,51,1.00);
	clear: both;
	margin: 30px 0 10px;
}

h1 {
	font-size: 30px;
	line-height: 1.2308;
	font-family: 'Oswald', sans-serif;
	font-weight: bold;
	text-transform:uppercase;
}

h2 {
	font-size: 25px;
	line-height: 1.875;
	font-family: 'Cabin', sans-serif;
}

h3 {
	font-size: 25px;
	line-height: 1.1667;
	font-family: 'Cabin', sans-serif;
	font-weight: lighter;
	color: #cb210d;
}

h1, h2, h3, h4, h5, h6, p {
	&.title-style {
		font-size: 20px;
		line-height: 1.2308;
		font-family: 'Oswald', sans-serif;
		text-transform:uppercase;
		font-weight:bold;
    color: #2d3033;
	}
	&.small-title {
    margin: 0 auto;
    display: inline-block;
    height: 41px;
    font-family: 'Cabin', sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: rgba(50,47,47,1.00);
	}
	&.minor-title {
		font-size: 20px;
		line-height: 1.2308;
		font-family: 'Cabin', sans-serif;
		font-weight:bold;
    color: #2d3033;
	}
	&.smaller-title-font {
		font-size: 20px;
	}
	&.larger-title-font {
		font-size: 30px;
	}
}

h4 {
	font-size: 20px;
	line-height: 1.4;
	font-family: 'Cabin', sans-serif;
	font-weight:700;
}

h5,
h6 {
	font-size: 17px;
	letter-spacing: 0.1em;
	line-height: 1.2353;
	text-transform: uppercase;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

p {
	margin: 0 0 10px;
	line-height:1.4375;
}

b,
strong {
	font-weight: 700;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

blockquote {
    color: rgba(159,3,6,1.00);
    font-size: 17px;
    font-style: italic;
    line-height: 1;
    margin: 0 0 25px 20px;
    font-weight: 400;
}
blockquote > blockquote {
	margin-left: 0;
}

blockquote p {
	margin-bottom: 35px;
}

blockquote > p:last-child {
	margin-bottom: 0;
}

blockquote cite,
blockquote small {
	color: #333;
	font-family: "Noto Sans", sans-serif;
	font-size: 17px;
	line-height: 1.6471;
}

blockquote em,
blockquote i,
blockquote cite {
	font-style: normal;
}

blockquote strong,
blockquote b {
	font-weight: 400;
}

address {
	font-style: italic;
	margin: 0 0 28px;
}

code,
kbd,
tt,
var,
samp,
pre {
	font-family: Inconsolata, monospace;
}

pre {
	background-color: #fcfcfc;
	border: 1px solid #eaeaea;
	font-size: 17px;
	line-height: 1.2353;
	margin-bottom: 28px;
	max-width: 100%;
	overflow: auto;
	padding: 14px;
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

abbr[title] {
	border-bottom: 1px dotted #eaeaea;
	cursor: help;
}

mark,
ins {
	background-color: #fff9c0;
	text-decoration: none;
}

sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	bottom: 1ex;
}

sub {
	top: .5ex;
}

small {
	font-size: 75%;
}

big {
	font-size: 125%;
}

.display-title{
	text-align:center;
	font-family: 'Oswald', sans-serif;
	margin-bottom:25px;
	h1{
		margin-top:0;
		font-size:20px;
		margin-bottom:10px;
		text-transform:uppercase;
	}
	&:after{
		content:"";
		display:block;
		width:200px;
		height:3px;
		background-color:rgba(163,21,23,1.00);
		margin:0 auto;
	}
	&.left-aligned {
		text-align: left;
		&:after{
			margin:0;
		}
	}
}

ul {
	margin-bottom:25px;
	list-style-position:inside;
	li {
		list-style: none;
		background-image: url(../img/blc-bullet.png);
		background-position: left top;
		background-repeat: no-repeat;
		padding: 3px 0;
    padding: 2px 0 6px 23px;
	}
}
.content ul li{
	margin-bottom:12px;
}

/*===============================================*/
/* 4.0 NAVIGATION
/*====================*/

.navbar {
  display: block;
  position: absolute;
  top: 0px;
  width: 100%;
	z-index:500;
}
.nav{
	text-align: right;
}
.navbar-header{
	float:none;
}
.navbar-nav > li > a {
    color: #FFF !important;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
}
.navbar-nav > li > a:hover {
    color: #000 !important;
    background-color: rgba(0, 0, 0, 0.06) !important;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
	background-color: transparent;
	color: #B70606 !important;
}

.primary-header {
    padding: 30px;
    font-family: 'Oswald', sans-serif;
    border: none;
    background-color: transparent;
}
.logohead {
    width: 200px;
    height: auto;
    padding: 0;
    float: left;
    margin-bottom: 7px;
}
.logohead img{
	width:100%;
	height:auto;
}
.nav-sticky .logohead {
    width: 150px;
}

.header-right{
	margin-top:10px !important;
	float:right;
}
.header-right > div{
	margin-right:10px;
	margin-top: 5px;
}
.head-contact{
	float:right;
}
.head-contact > * {
    float: right;
    margin-left: 10px;
}
.head-contact .phonenumber {
    font-size: 19px;
    color: #383838;
}
.head-contact .phonenumber a{
    color: #383838;
    text-decoration: none;
}
.head-contact .mail,
.head-contact .twitter,
.head-contact .linkedin{
	width:23px;
	height:23px;
	background-size:100%;
	background-repeat:no-repeat;
	background-position:center center;
    margin-top: -2px;
}
.head-contact .twitter{background-image:url(../img/twit.png);}
.head-contact .mail{background-image:url(../img/mail.png);}
.head-contact .linkedin{background-image:url(../img/linkedicon.png);}

.navbar-toggle {
    margin: -4px 0px 0px;
	border:none;
	border-radius:0;
	&:hover {
		cursor: pointer;
	}
}
.navbar-collapse{
	margin-top: 20px;
}

/* ++++ NAVBAR changes :) +++ */
.navbar{
	display:block;
	border-radius:0;
}
.nav-animate{
	-webkit-transition:margin-top 1s ease-in-out;
	-moz-transition:margin-top 1s ease-in-out;
	-o-transition:margin-top 1s ease-in-out;
	transition:margin-top 1s ease-in-out;
	margin-top:-200px;
}
.nav-disappear{
	display:none;
}
.navbar.primary-header.nav-sticky {
	position:fixed;
	background-color: white;
	margin-top:0;
    -webkit-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.03);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.nav-sticky .logohead {
    width: 150px;
}
.nav-sticky.primary-header {
    padding: 10px 10px 0;
}
@media only screen and (min-width : 768px) {
.nav-sticky .navbar-collapse {
    margin: 0 -6px 0 0;
    float: right;
    padding: 0px;
}
.nav-sticky .header-right{
	margin-top:10px !important;
	}
}
@media only screen and (min-width : 992px) {
.nav-sticky .navbar-collapse {
    margin: -27px -6px 0 0;
    float: right;
    padding: 0px;
}
.nav-sticky .header-right{
	margin-top:0 !important;
	}
}

.nav-sticky .navbar-nav {
    text-align: right;
    float: none !important;
    position: static !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
}
.nav-sticky .navbar-nav > li > a {
    font-size: 12px;
}
.nav-sticky .navbar-nav > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
	color:rgba(96,96,96,1.00) !important;
}

.admin-bar .navbar{
	margin-top:46px;
}

.nav-sticky .navbar-toggle .icon-bar{
    background-color: black;
}
.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
    background-color: #c80000;
}

/*===============================================*/
/* 5.0 SITEWIDE ELEMENTS
/*====================*/

section {
	padding-bottom:30px;
	position:relative;
	-webkit-box-shadow: 0 -5px 60px 0 #000000;
	box-shadow: 0 -5px 60px 0 #000000;
	z-index:101;
	&.section-unstyled {
		padding-bottom: 0;
		box-shadow: none;
		z-index: initial;
	}
}

.no-shadow{
	box-shadow:none;
}

.tri-top{
	position:absolute;
	top:0;
	left:0;
	z-index:50;
}
.tri-top div{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 100px 100px 0 0;
	border-color: #ffffff transparent transparent transparent;
	line-height: 0px;
	_border-color: #ffffff #000000 #000000 #000000;
	_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}

.sec-corner-cut{
	width:100%;
	height:45px;
	margin-top:-45px;
	position:absolute;
	z-index:200;
}

.sec-corner-cut .corner{
	float:left;
	width:45px;
	height:45px;
}

.sec-corner-cut .corner .cc{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 0 45px 45px;
	line-height: 0px;
	background-color:transparent !important;
}

.sec-corner-cut .width{
	float:left;
	width: calc(100% - 45px);
	height:45px;
}

.hideme{
	-webkit-transition:padding-top 1s ease-in-out;
	-moz-transition:padding-top 1s ease-in-out;
	-o-transition:padding-top 1s ease-in-out;
	transition:padding-top 1s ease-in-out;
	opacity:0;
}
.showme{
	opacity:1.0 !important;
}

.email-link{
	font-size:18px;
}
.email-link:before{
	background-image: url(../img/email-icon-black.png);
}
.email-link_red:before{
	background-image: url(../img/email-icon-red.png);
}

.phone-link{
	font-size:20px;
}
.phone-link:before{
	background-image: url(../img/phone-icon-black.png);
}
.phone-link_red:before{
	background-image: url(../img/phone-icon-red.png);
}

.location-link:before{
	background-image: url(../img/location-icon-black.png);
}
.location-link_red:before{
	background-image: url(../img/location-icon-red.png);
}

.twitter-link a{
	font-size:22px;
	color:#2cb5e7;
	font-weight:bold;
}
.twitter-link:before{
	background-image: url(../img/twitter-blue.png);
	margin-top:-8px;
}

.linkedin-link img{
	width:150px;
	height:auto;
}

/*===============================================*/
/* 6.0 Colour usage
/*====================*/


.hue_light-grey, 
.hue_light-grey .cc, 
.hue_light-grey .width{
	background-color:rgba(212,218,220,1.00);
	border-color: transparent transparent rgba(212,218,220,1.00) transparent;
	_border-color: #000000 #000000 rgba(212,218,220,1.00) #000000;
	_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}
.hue_dark-grey, 
.hue_dark-grey .cc, 
.hue_dark-grey .width{
	background-color:rgba(39,45,49,1.00);
	border-color: transparent transparent rgba(39,45,49,1.00) transparent;
	_border-color: #000000 #000000 rgba(39,45,49,1.00) #000000;
	_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
	color:white
}
.hue_medium-grey, 
.hue_medium-grey .cc, 
.hue_medium-grey .width{
	background-color: rgba(166,166,164,1.00);
	border-color: transparent transparent rgba(166,166,164,1.00) transparent;
	_border-color: #000000 #000000 rgba(166,166,164,1.00) #000000;
	_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
	background-image:url(../img/bl-pattern-4pc.png);
	background-size:300px;
}
.hue_black, 
.hue_black .cc, 
.hue_black .width{
	background-color:rgba(14,14,13,1.00);
	border-color: transparent transparent rgba(14,14,13,1.00) transparent;
	_border-color: #000000 #000000 rgba(14,14,13,1.00) #000000;
	_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}
.hue_white, 
.hue_white .cc, 
.hue_white .width{
	background-color:rgba(255,255,255,1.00) ;
	border-color: transparent transparent rgba(255,255,255,1.00) transparent;
	_border-color: #000000 #000000 rgba(255,255,255,1.00) #000000;
	_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}

.colour_red{
	color: rgba(233,0,3,1.00) !important;
}
.colour_dark-red{
	color: #B70606 !important;
}
.colour_grey{
	color: #606060 !important;
}

/*===============================================*/
/* 7.0 PAGE ELEMENTS
/*====================*/

/* 7.1 home page
/* ------------- */

/* 7.1.1 Jumbotron / Masthead
*/
#fullheight {
    padding-bottom: 45px;
    min-height: 700px;
}
.masthead{
	background-image: url(../img/vidbg.jpg);
	background-position:center center;
	background-size:cover;
	position:relative;
	z-index:100;
	overflow: hidden;
}
.mast-content{
	position:absolute;
	bottom:10%;
	right:50px;
	max-width:600px;
}
.jumbotext {
  position: absolute;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  margin-top: 200px;
	opacity:1;
	-webkit-transition:all 0.6s ease-in-out;
	-moz-transition:all 0.6s ease-in-out;
	-o-transition:all 0.6s ease-in-out;
	transition:all 0.6s ease-in-out;
}
.jumbotext img{
	width:100%;
	height:auto;
}
.jumbotext.jumbo-swoop-away{
	left:0;
	opacity:0;
}
#bgvid_cover{
	position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -99;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: url(../img/loading-static.png);
  }

video#bgvid { 
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover; 
}

/* 7.1.2 Introduction section */

.section-spacer{
	height:200px;
}

.right-copy{
	line-height:1.3;
}

/* 7.1.3 Case studies slider section
*/
.casestudies-section{
	position:relative;
	background-image: url(../img/bg-grey.jpg);
	padding:30px 15px 0;
}
.background-cut{
	display:none;
}

.casestudy-holder{
	position:static;
	margin-bottom:120px
}

.casestudy-box{
	max-width:600px;
	width:100%;
	background-color:rgba(229,33,36,1.00);
	margin:0 auto;
	border:7px solid white;
	-webkit-box-shadow: 1px 1px 10px -7px rgba(0,0,0,0.75);
	-moz-box-shadow: 1px 1px 10px -7px rgba(0,0,0,0.75);
	box-shadow: 1px 1px 10px -7px rgba(0,0,0,0.75);
}

.flexslider .slides > li{
    overflow: hidden;
    height: 280px;
		position: relative;
		background-image: none;
}
.flexslider {
	ul {
		li {
			background-image: none;
		}
	}
}

/* 7.1.4 Sevices section */

.service-row > div > div{
	margin:0 10%;
	text-align:center;
}
.service-row h4, .service-row h3 {
    margin:0 auto;
    display:inline-block;
    height: 41px;
		font-size:12px;
		color:rgba(50,47,47,1.00);
}
.service-row img{
	width:40%;
	height:auto;
	min-width:80px;
	display:block;
	margin:0 auto;
}
.service-icon{
	position:relative;
	opacity:0;
	-webkit-transition:all 1.2s ease-in-out;
	-moz-transition:all 1.2s ease-in-out;
	-o-transition:all 1.2s ease-in-out;
	transition:all 1.2s ease-in-out;
  display: block;
  text-align: center;
}
.service-icon:hover{
    background-color:#bcbcbc;
}

/* 7.1.5 Testimonial section */
.testimonial-section{
	padding-bottom:0;
	position:relative;
	padding-top:50px;
	color:white;
}
.background-cut-right{
	background-image:url(../img/bg-grey.jpg);
	background-size:75%;
	height:330px;
	width:65%;
	margin-left:35%;
	-webkit-box-shadow: 20px 15px 40px 0 #000000;
	box-shadow: 20px 15px 40px 0 #000000;
	display:none;;
}
.testimonial-vid-holder{
	position:relative;
	width:100%;
	top:0;
	background-image: url(../img/bg-grey.jpg);
	padding:30px 0;
}

.testimonial-video-wrapper{
	width:100%;
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	-webkit-box-shadow: 1px 1px 10px -7px rgba(0,0,0,0.75);
	-moz-box-shadow: 1px 1px 10px -7px rgba(0,0,0,0.75);
	box-shadow: 1px 1px 10px -7px rgba(0,0,0,0.75);
}

.testimonial-video-wrapper video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.testimonial-video-text{
	margin:20px 0 50px;
}
.testimonial-video-text h5{
    font-weight: bold;
    font-size: 23px;
}

/* 7.2 PAGES
/* ------------- */
.website-content{
	min-height:400px;
	background-color:white;
}

.pages-content{
	padding-bottom:140px;
}
.title-tagline-top {
    background-color: rgb(245, 245, 245);
}
.page-body{
		padding-top: 60px;
		@media(max-width: 1023px ) {
			padding-top: 40px
		}
}
.services-page {
	.page-body{

		h1 {
			font-size:30px;
			font-weight:600;
			text-transform:initial;
		}

			@media(max-width: 1023px ) {
			padding-top: 20px
		}
	}
}
.page-news-post{
	display:none;
	width:300px;
	float:right;
	text-align:right;
	position:relative;
	top:-15px;
}
.page-news-post h4,
.page-news-post p
{
	margin:0;
	color:white;
}
.page-news-post p{
	font-size:11px;
}
.page-news-post h4{
	font-size:13px;
}
.page-news-post a{
	color: rgba(136,0,2,1.00);
	font-size:12px;

}
.page, .archive-page {
	.content{
		margin-top:40px;
	}
}

.page .form-holder{
	padding:10px;
}

/* ++++ page title header +++ */

.about-page .page-title-header{
	background: #bcbcbc; /* Old browsers */
	background: url(../img/bl-pattern-4pc.png), -moz-linear-gradient(45deg, #bcbcbc 0%, #848484 100%);
	background: url(../img/bl-pattern-4pc.png), -webkit-linear-gradient(45deg, #bcbcbc 0%,#848484 100%);
	background: url(../img/bl-pattern-4pc.png), linear-gradient(45deg, #bcbcbc 0%,#848484 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bcbcbc', endColorstr='#848484',GradientType=1 );
	background-size:300px, auto;
}
.services-page .page-title-header{
	background: #ff3019; /* Old browsers */
	background: url(../img/bl-pattern-8pc-black.png), -moz-linear-gradient(45deg,  #ff3019 0%, #c91414 44%, #bc1212 46%, #cf0404 100%);
	background: url(../img/bl-pattern-8pc-black.png), -webkit-linear-gradient(45deg,  #ff3019 0%,#c91414 44%,#bc1212 46%,#cf0404 100%);
	background: url(../img/bl-pattern-8pc-black.png), linear-gradient(45deg,  #ff3019 0%,#c91414 44%,#bc1212 46%,#cf0404 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3019', endColorstr='#cf0404',GradientType=1 );
	background-size:430px, auto;
}
.case-studies-page .page-title-header{
	background: #45484d; /* Old browsers */
	background: url(../img/bl-pattern-4pc.png), -moz-linear-gradient(45deg,  #45484d 0%, #000000 100%);
	background: url(../img/bl-pattern-4pc.png), -webkit-linear-gradient(45deg,  #45484d 0%,#000000 100%);
	background: url(../img/bl-pattern-4pc.png), linear-gradient(45deg,  #45484d 0%,#000000 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#000000',GradientType=1 ); 

}
.inspiration-page .page-title-header, .blog-page .page-title-header{
	background: #ad0000; /* Old browsers */
	background: 
		url(../img/bl-pattern-4pc.png),
		-moz-linear-gradient(45deg,  #ad0000 0%, #ef442d 45%, #f6290c 50%, #f02f17 71%, #e73827 100%); 
	background: 
		url(../img/bl-pattern-4pc.png),
		-webkit-linear-gradient(45deg,  #ad0000 0%,#ef442d 45%,#f6290c 50%,#f02f17 71%,#e73827 100%);
	background: 
		url(../img/bl-pattern-4pc.png),
		linear-gradient(45deg,  #ad0000 0%,#ef442d 45%,#f6290c 50%,#f02f17 71%,#e73827 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ad0000', endColorstr='#e73827',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	background-size:450px, auto;
}
.testimonials-page .page-title-header{
	background: #444444; /* Old browsers */
	background: url(../img/bl-pattern-4pc.png), -moz-linear-gradient(45deg,  #444444 1%, #727272 32%, #4e4e4e 76%, #383838 87%, #1b1b1b 100%);
	background: url(../img/bl-pattern-4pc.png), -webkit-linear-gradient(45deg,  #444444 1%,#727272 32%,#4e4e4e 76%,#383838 87%,#1b1b1b 100%);
	background: url(../img/bl-pattern-4pc.png), linear-gradient(45deg,  #444444 1%,#727272 32%,#4e4e4e 76%,#383838 87%,#1b1b1b 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#444444', endColorstr='#1b1b1b',GradientType=1 );
	background-size:250px, auto;
}
.contact-page .page-title-header{
	background: #ff3019; /* Old browsers */
	background: url(../img/bl-pattern-4pc.png), -moz-linear-gradient(45deg,  #ff3019 0%, #c91414 44%, #bc1212 46%, #cf0404 100%);
	background: url(../img/bl-pattern-4pc.png), -webkit-linear-gradient(45deg,  #ff3019 0%,#c91414 44%,#bc1212 46%,#cf0404 100%);
	background: url(../img/bl-pattern-4pc.png), linear-gradient(45deg,  #ff3019 0%,#c91414 44%,#bc1212 46%,#cf0404 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3019', endColorstr='#cf0404',GradientType=1 );
	background-size:500px, auto;
}

.page-title-header{
	padding:120px 0 20px;
	background:url(../img/bl-pattern-4pc.png) 300px;
	background-color:rgba(36,37,38,1.00);
	background-size:300px, auto;
}

.page-title-header h1, .page-title-header .title-style{
	color:rgba(255,255,255,1.00);
	font-weight:bold;
	float:left;
}
.page-title-header h2{
	font-size: 30px;
	line-height: 1.2308;
	font-family: Oswald,sans-serif;
	font-weight:bold;
	text-transform: uppercase;
	color:rgba(255,255,255,1.00);
	float:left;
}

.page-intro-text {
  margin-bottom: 30px;
  font-size: 18px;
  max-width: 100%;
	line-height:1.2;
	font-weight:bold;
  text-align: center;
}

/* 7.2.1 About Page */
.personnel{
	margin-bottom:70px;	
}

.personnel:not(:last-child):after{
	content:"";
	display:block;
	width:80%;
	margin:20px auto;
	height:1px;
	background-color: rgb(192, 192, 192);
}

.personnel-title{
	margin-bottom:20px;
}

.personnel h1, .personnel h2, .personnel h3{
	margin:0 10px 10px 0;
	display:inline-block;
}

.personnel-picture{
	margin:0 5% 20px 0;
}

.personnel-picture img{
	width:100%;
	max-width:350px;
	height:auto;
	display:block;
	margin:0 auto;
	-webkit-box-shadow: 5px 5px 8px -5px rgba(0,0,0,0.75);
	-moz-box-shadow: 5px 5px 8px -5px rgba(0,0,0,0.75);
	box-shadow: 5px 5px 8px -5px rgba(0,0,0,0.75);
}
.personnel-bio {
	@media(min-width: 776px) {
		padding-left: 45px;
	}
}
.personnel-links{
	margin-bottom:20px;
}
.personnel-links span{
	display:block;
	margin-bottom:20px;
	&.email-link_red {
		> a {
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
			max-width: calc(100% + 55px);
			font-size: 15px;
			@media(max-width: 992px) {
				max-width: calc(100% + 30px);
			}
			@media(max-width: 776px) {
				max-width: 100%;
			}
		}
	}
}
.personnel-links span:before{
	content:"";
	width:35px;
	height:35px;
	float:left;
	background-size:cover;
	margin:-8px 10px 0 0;
}

/* 7.2.2 Services Page */
a.anchor {
    display: block;
    position: relative;
    top: -160px;
    visibility: hidden;
}

.service-hold{
	margin-bottom:20px;	
}
.service-hold:after{
	content:"";
	display:block;
	width:70%;
	margin:20px auto;
	height:1px;
	background-color: rgb(192, 192, 192);
}

.service-hold h4 {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 18px;
	margin-top:18px;
}
.service-hold img{
	width:70%;
	height:auto;
	display:block;
	max-width:250px;
	margin:0 auto;
}

.service-image img{
	display:block;
	width:100%;
	height:auto;
	max-width:150px;
	margin:0 auto;
}
.service-text{
    margin: 0px 10%;
    text-align: center;
}
.service-content {
	padding-bottom: 15px;
	h3, .minor-title {
		@media (max-width: 991px) {
			text-align: center;			
		}
	}
	ul {
		margin-left: 16px;
	}
	&.service-content-bordered {
		border-bottom: 1px solid rgb(192, 192, 192);
	}
}

.service-holder {
	margin-bottom: 30px;
	&:nth-child(odd) {
		clear: both;
	}
	.service-flex-row {
		display: block;
		display: flex;
		align-items: center;
		@media(max-width: 991px) {
			flex-direction: column;
		}
	}
}
[class*="svc-col"] {
	&:after{
		content:"";
		display:block;
		width:70%;
		margin:20px auto;
		height:1px;
		background-color: rgb(192, 192, 192);
	}	
	&[class*="-full"] {
		&:after{
			width: 100%;
		}
	}
}

/* 7.2.3 Inspiration Page */

.insp-content{
    background-color: red;
    padding: 67px;
    color: white;
    font-size: 16px;
    text-align: center;
    background-color: #cc0000;
    background-image: url(../img/insp-b-bg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    top: -60px;
    position: relative;
    border-bottom: 5px solid rgba(163,21,23,1.00);
}

.insp-content p{
    line-height: 1.8;
}

.insp-list{
    padding: 0px 40px;
}

.insp-list h5{
    color: red;
    font-size: 20px;
}
.insp-list ul li {
    margin-bottom: 20px;
    font-size: 15px;
    margin-left: 25px;
}

.insp-form{
	background: #45484d; /* Old browsers */
	background: url(../img/bl-pattern-4pc.png), -moz-linear-gradient(45deg,  #45484d 0%, #000000 100%);
	background: url(../img/bl-pattern-4pc.png), -webkit-linear-gradient(45deg,  #45484d 0%,#000000 100%);
	background: url(../img/bl-pattern-4pc.png), linear-gradient(45deg,  #45484d 0%,#000000 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#000000',GradientType=1 ); 
	background-size:430px, auto;
    padding: 43px;
}
.insp-form-title {
    margin-top: 0;
    margin-bottom: 30px;
}
.insp-form-title h2{
    color: white;
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
}
.insp-form-title:after {
    content: "";
    display: block;
    width: 200px;
    height: 3px;
    background-color: rgba(163,21,23,1.00);
    margin: 20px auto 0;
}

.insp-form .opt-in-container{
	color: #ffffff;
}

/* 7.2.4 Testimonial Page */
.testimonials-page .page-video-wrap{
	max-width:450px;
}
.testimonial-hold{
	margin-bottom:20px;	
}
.testimonial-hold:after{
	content:"";
	display:block;
	width:70%;
	margin:20px auto;
	height:1px;
	background-color: rgb(192, 192, 192);
}

.client-logo img{
	width:100%;
	max-width:150px;
	display:block;
	margin:0 auto;
}

/* 7.2.5 Contact Page */
.contact-entry-hold{
	margin-bottom:20px;	
}
.contact-entry-hold:after{
	content:"";
	display:block;
	width:70%;
	margin:20px auto;
	height:1px;
	background-color: rgb(192, 192, 192);
}
.contact-links{
	margin:20px 0;
}
.contact-links span {
    display: block;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(218, 218, 218);
    font-family: 'Oswald', sans-serif !important;
}
.contact-links span::before {
    content: "";
    width: 35px;
    height: 35px;
    float: left;
    background-size: 35px;
    margin: -8px 10px 0 0;
    background-repeat: no-repeat;
}

/* 7.2.5 Case studies Page */
.cs-wrap{
    max-width: 80%;
    margin: 0px auto;
}
.cs-holder {
    position: relative;
    max-width: 350px;
    margin: 0 auto 30px;
}
a.cs-box{
	height:300px;
	display:block;
	background-size:cover;
	position:relative;
	overflow:hidden;
}
.cs-box:before{
	content:"";
	height:100%;
	width:100%;
	background-color: rgba(0, 0, 0, 0.89);
	position:absolute;
	top:0;
	-webkit-transition: all 0.6s ease-in-out;
  	-moz-transition: all 0.6s ease-in-out;
  	-o-transition: all 0.6s ease-in-out;
  	transition: all 0.6s ease-in-out;
}
.cs-box:hover:before {
	background-color: rgba(255, 0, 0, 0.77);
}
.cs-content{
	position:absolute;
	z-index:20;
	top:0;
	padding:30px;
  width: 100%;
}
.cs-content .logo-img{
    width: 160px;
    float: right;
}
.cs-content .logo-img img {
    width: 100%;
    height: auto;
    max-width: 450px;
    display: block;
    margin: 0 auto 20px;
}
.cs-content h2, .cs-content h3 {
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1.3;
		text-align: right;
		font-weight: normal;
}
.cs-holder .tri-top div {
    border-width: 50px 50px 0 0;
}

/* 7.2.6 Testimonial Page */
.testimonial-image-display {
    padding: 20px;
}

.testimonial-image-display img{
    width: 100%;
    height: auto;
    max-width: 150px;
    display: block;
    margin: 0 auto;
}

.testimonial-content .display-title{
  color: black !important;
  display: inline-block;
	margin-top:20px;
}

.testimonial-content .display-title h1{
 color: black !important;
}

.testimonial-row:not(:last-child){
	margin-bottom:20px;
	padding-bottom:20px;
	border-bottom:1px rgba(175,175,175,1.00) solid;
}

/* 7.2.7 Downloads Page */
.pdfdownload {
    font-size: 20px;
    padding: 25px;
    border-bottom: 3px solid #ddd;
    display: block;
}
.pdfdownload:before {
    content: "";
    display: inline-block;
    height: 40px;
    width: 40px;
    background-image: url(../img/downloadpdf.png);
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
    margin-top: -10px;
}

/* 7.2.8 Search Results */
.search-result{
    margin-top: 25px;
    
}
.search-result:after{
	content:"";
	display:block;
	width:70%;
	margin:20px auto;
	height:1px;
	background-color: rgb(192, 192, 192);
}

/*===============================================*/
/* 8.0 POSTS
/*====================*/

.pages-content{
	padding-bottom:140px;
}
.title-tagline-top {
    background-color: rgb(245, 245, 245);
}

.post .content{
	margin-top: 40px;
}
.post .post-content{
	margin-top:100px;
	margin-bottom:100px;
}
.blog, .testimonial, .case-study, .archive-post {
	.post-content {
		margin-top: 30px;
		margin-bottom: 100px;
		padding-bottom: 30px;
	}
	.case-study-image-display, .testimonial-image-display, .blog-post-image-display {
		img {
			display: block;
			margin: 10px auto;
		}
	}
	.case-study-content, .testimonial-content, .blog-post-content {
		margin-bottom: 30px;
	}
}
.testimonial-post {
	.testimonial-image-display {
		padding: 0 15px;
	}
}
// archive page content
.archive-page {
	.blog, .testimonial, .case-study, .archive-post {
		.post-content{
			margin-top: 30px;
			margin-bottom: 15px;
			padding-bottom: 5px;
			@media (max-width: 992px) {
				margin-top: 20px;
				margin-bottom: 10px;
				padding-bottom: 10px;
			}
		}
		.case-study-image-display, .testimonial-image-display, .blog-post-image-display {
			@media (max-width: 992px) {
				img {
					display: none;
				}
			}
		}
	}
}
.post .page-title-header h1 {
    color: rgba(255,255,255,1.00);
    font-weight: bold;
    float: none;
}
.post h1.entry-title {
	color:white;
}

.post .form-holder{
	padding:10px;
}
.post .entry-meta{
	color:rgba(255,255,255,1.00);
}
.post .entry-meta a{
	color:rgba(255,255,255,1.00);
}

.case-study, .testimonial {
		.title-tagline-top {
			background-size: cover;
			background-repeat:no-repeat;
			background-position:top 50%;
			padding: 20px 0;
			position:relative;
	}
}
.case-study, .testimonial {
	.title-tagline-top:before{
		content:"";
		height:100%;
		width:100%;
		background-color: rgba(95, 95, 95, 0.86);
		position:absolute;
		top:0;
	}
}
.post .post-image-display{
	max-width:450px;
	display:block;
	margin:0 auto;
}
.post .post-image-display img{
	width:100%;
	height:auto;
	border: 8px white solid;
	-webkit-box-shadow: 5px 5px 8px -5px rgba(0,0,0,0.75);
	-moz-box-shadow: 5px 5px 8px -5px rgba(0,0,0,0.75);
	box-shadow: 5px 5px 8px -5px rgba(0,0,0,0.75);
}
.extra-post-img{
	display:none;
}
.extra-post-img img{
	width:100%;
	height:auto;
	max-width:450px;
	display:block;
	margin:0 auto 20px;
}
.extra-post-img-empty{
	background-image: url(../img/bl-pattern-2pc.png);
}
.flex-caption .extra-post-img {
    bottom: 0;
    right: 0;
    width: 130px;
}

// archives
.archive-link {
	display: block;
	margin: 0;
	text-align: right;
	clear: both;
	a {
		color: $red;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
		width: 100%;
		margin: 5px 0;
    padding: 6px 10px;
	}
	&.spaced-archive-link {
		margin: 20px 0;
	}
	+ h3, + .blog-subtitle {
    display: inline-block;
    margin: 5px 0;
    font-family: Cabin,sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #322f2f;
	}
}

/*===============================================*/
/* 9.0 FOOTER
/*====================*/

/* 9.1 Footer Links Section
/* ------------- */

.twitterlogo{
	height:50px;
	width:100%;
	
	background-image:url(../img/tweets.png);
	background-repeat:no-repeat;
	background-size:contain;
}

.footer-links-section{
	padding-bottom:60px;
}

#twitter-widget-holder{
	padding-bottom:20px;
	
}
#twitter-widget-holder iframe{
	margin:0 auto;
    display: block !important;
}

.ft-link{
    max-width: 450px;
}

/* ++++ boxes +++ */
.box {
    display: block;
    margin-bottom: 26px;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
  	-moz-transition: all 0.3s ease-in-out;
  	-o-transition: all 0.3s ease-in-out;
  	transition: all 0.3s ease-in-out;
    top: 0;
    right: 0;
}
.box:hover {
    top: -4px;
    right: 4px;
}
.footer-links-section .box img{
	width:100%;
	height:auto;
	
}

/* 9.2 Footer
/* ------------- */

footer .foot-col{
	margin-bottom: 50px;
} 
footer .foot-col {
	.title-style {
    font-size: 26px;
    margin-bottom: 20px;
	}
}

footer .logo img{
	width:50%;
	height:auto;
	max-width:300px;
}
footer .webnames{
	margin-top: 20px;
}
footer .name{
	font-size: 18px;
	color: white;
}
.footname{
	margin-bottom: 18px;
}
footer h2{
	font-size: 20px;
	color: white;
	display: block;
	border-bottom: 2px solid #16171A;
	margin-bottom: 10px;
	padding-bottom: 3px;
}
footer ul{
	list-style: none;
	background-image: none;
	list-style-position: inside;
	padding-left:0;
	li {
		background-image: none;
    padding: 3px 0;
	}
}
.nav {
  list-style: none;
	li {
		background-image: none;
    padding: 3px 0;
	}
}
footer ul * {
    margin-bottom: 17px;
}
footer ul a{
	color:white;
}
footer .quicklinks li {
    margin: 32px 15px 20px 0;
    font-size: 14px;
	color:rgba(203,203,203,1.00);
}
footer .quicklinks li:before{
	content:"";
	width:28px;
	height:28px;
	float:left;
	margin-right:10px;
	margin-top:-5px;
}
.foot-address:before{
	background-image:url(../img/location-icon.png);
	background-size:cover;
}
.foot-phone:before{
	background-image:url(../img/phone-icon.png);
	background-size:cover;
}
.foot-email:before{
	background-image:url(../img/email-icon.png);
	background-size:cover;
}
.foot-bar {
    background-color: rgb(29, 29, 29);
    color: rgba(114,114,114,1.00);
    font-size: 12px;
    padding: 20px 0;
    position: relative;
    z-index: 201;
}

.foot-form{
	font-size:15px
}

.opt-in-container .nf-field-label{
	float: right;
	margin-left: 5%;
	width: 90%;
	line-height: 1.2856;
}
.opt-in-container .nf-field-element{
	float: left;
	margin-left: -95% !important;
	margin-top: 3px !important;
	width: auto !important;
}
.opt-in-container .nf-field-element > input{
	width: 1%;
}

.footform .textarea-wrap textarea{
	height:120px;
}

/*===============================================*/
/* 10.0 NINJA FORMS FIXES
/*====================*/

input, textarea{
	padding:10px;
	background-color: rgba(95,95,95,1.00);
	border:none;
	border-bottom:3px solid white;
	color:rgba(220,216,216,1.00);
	width:100%;
	max-width:340px;
	display:block;
	-webkit-transition: all 0.6s ease-in-out;
  	-moz-transition: all 0.6s ease-in-out;
  	-o-transition: all 0.6s ease-in-out;
  	transition: all 0.6s ease-in-out;
}
input:focus, textarea:focus{
	border-bottom:3px solid red;
}

.field-wrap, #ninja_forms_required_items {
    margin-bottom: 10px !important;
}
.textarea-wrap textarea{
	height:120px !important;
}
.submit-button_red, .search-submit{
	padding:10px 20px !important;
	background-color:rgba(225,22,25,1.00) !important;
	color:white;
	border:none;
	display:block;
}

.submit-button_white{
	padding:10px 20px !important;
	background-color:#ffffff !important;
	color:rgba(225,22,25,1.00) !important;
	border:none;
	display:block;
}
.search-field{
	margin-bottom:10px;
	width:100%;
	max-width:340px;
}

.ninja-forms-required-items{
    display: none;
}

.ninja-forms-error-ms, .ninja-forms-success-msg {
    padding: 6px 10px;
    background-color: black;
    color: white;
    margin-bottom: 10px;
    text-align: center;
    border-bottom: 3px solid red;
}

#nf-form-6-cont .nf-form-fields-required{
    color: inherit;
    margin-bottom: 5px;
}

.nf-form-fields-required{
    color: white;
    margin-bottom: 5px;
}

.nf-field-container, #ninja_forms_required_items {
    clear: both;
    position: relative;
    margin-bottom: 12px;
}

#nf-form-10-cont .nf-response-msg{
    color: white;
    margin-bottom: 20px;
    text-align: center;
}

#nf-form-10-cont .submit-button_red{
    margin-top: 20px;
    display: inline-block;
}


/*===============================================*/
/* 10.0 IMAGE-SPRITES completion
/*====================*/

.sprite {
	background-image: url(../img/image-sprite.png);
}

@media (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
	.sprite {
		 background-image: url(../img/image-sprite.png);
		 background-size: 528px 50px;
	}
}

/*===============================================*/
/* 11.0 COOKIES
/*====================*/

.cookie-notification {
	color: #000000;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000 !important;
}
.cookie-notification .cookie-content{
	background: #ffffff;
	border: 3px solid rgba(225,22,25,1.00) !important;
	padding: 10px 40px 0;
	margin: 30px;
	position: relative;
}

.cookie-notification .close {
	color: rgba(225,22,25,1.00);
	display: inline;
	position: absolute;
	top: 3px;
	right: 8px;
	font-size: 1.5em;
}
.cookie-notification .btn {
	margin: 3px;
	width: 200px;
	display: inline-block;
}

/*===============================================*/
/* 11.0 @media queries
/*====================*/

@media 
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi),
(-webkit-min-device-pixel-ratio: 1.25), 
(min-resolution: 120dpi),
(-webkit-min-device-pixel-ratio: 1.3), 
(min-resolution: 124.8dpi),
(-webkit-min-device-pixel-ratio: 1.5), 
(min-resolution: 144dpi)
 {
    
}
	
    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 480px) {
		.service-hold img {
    		width: 70%;
			}
    }	

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
        
        .desk{
            display: block;
        }
		
		body{
			font-size:16px;
		}
		
		.service-hold img {
    		width: 100%;
		}
		
		/* NAVIGATION */
		/*-----------*/
		.home-page-header .navbar-nav{
			float:none;
			position:absolute;
			left:0;
			right:0;
			top: 120px;
		}
		.navbar-nav{
			text-align:center;
		}
		.navbar-nav > li {
			display: inline-block !important;
			float:none;
		}
		
		.admin-bar .navbar{
			margin-top:32px;
		}
		
		/* PAGE */
		/*-----------*/
		.page-news-post{
			display:block;
		}
		.page-intro-text{
			h2, h3 {
				font-size: 17px;
				letter-spacing: 0.1em;
				line-height: 1.2353;
				text-transform: uppercase;
				font-family: 'Cabin', sans-serif;
			}
		}
		
		/* HOME PAGE */
		/*-----------*/
		.jumbotext {
			margin-top: 400px;
		}
		
		.personnel-links span{
			margin-bottom:10px;
		}
		.personnel-links span:before{
			content:"";
			width:20px;
			height:20px;
			margin:-4px 10px 0 0;
		}
		.personnel-links .email-link{
			font-size:12px;
		}
		.personnel-links .phone-link{
			font-size:16px;
		}

		.personnel-links .twitter-link a{
			font-size:18px;
			color:#2cb5e7;
			font-weight:bold;
		}
		.personnel-links .twitter-link:before{
			margin-top:-3px;
		}
		
		.personnel-links .linkedin-link img{
			width:100px;
			height:auto;
		}
		
		.background-cut{
			display:block;
			background-image:url(../img/bg-grey.jpg);
			background-size:75%;
			height:330px;
			width:60%;
			-webkit-box-shadow: -20px 15px 40px 0 #000000;
			box-shadow: -20px 15px 40px 0 #000000;
		}
		
		.casestudy-holder{
			position:absolute;
			width:100%;
			top:0;
			margin-bottom:0;
		}
		.casestudies-section{
			padding-bottom:0;
			padding-top:80px;
			padding:80px 0 0;
			background-image: none;
		}
		
		.extra-post-img{
		display:block;
		}
	}
	
    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
		
		/* HOME PAGE */
		/*-----------*/
		.left-title{
			padding-top:160px;
		}
		.testimonial-vid-holder{
			position:absolute;
			background-image: none;
		}
		.background-cut-right{
			display:block;
		}
		.testimonial-video-text{
			margin:70px 0 0;
		}
		
		.service-hold img {
    		width: 70%;
		}
		
		/* POSTS */
		/*-----------*/
		.post .post-image-display {
			position: absolute;
			top: -56px;
			width: 370px;
		}
        
		.insp-form-title {
			margin-top: 36px;
			margin-bottom: 30px;
			} 
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {
		
		.service-hold img {
    		width: 70%;
			}
    }