// menu tweaks //

// header menu
#navbar {
  .nav.navbar-nav#menu-header-menu {
    &#menu-header-menu {
      > li {
        > a {
          font-size: 16px;
          font-weight: 700;
          font-family: 'Oswald', sans-serif;   
          .caret {
            color: #606060;
          }
          &:hover {
            color: $black !important;
            .caret {
              color: $black;
            }
          }
        }
        &:hover {
          > a {
            color: $red;
          }
        }
        // dropdowns
        &.dropdown {
          &.open {
            > a {
              background-color: #f5f5f5;
              color: $black !important;  
              .caret {
                color: $black;
              }
            }
          }
          // sub items
          .dropdown-menu {
            > li {
              &.active {
                a {
                  background-color: #f5f5f5;
                  color: $red;
                  font-family: 'Cabin', sans-serif;  
                  font-weight: bold; 
                }
              }
            }
          }
        }
      }
      // mobile adjustments
      @media(max-width: 767px) {
        > li {
          margin: 8px 0;
          &.dropdown {
            a {
              .caret {
                color: $black;
              }
            }
            &.open {
              > a {
                background-color: rgba(0, 0, 0, 0.4) !important;
              }
              .dropdown-menu {
                padding: 0;
                text-align: right;
                > li {
                  a {
                    background-color: rgba(0, 0, 0, 0.2) !important;
                    color: $black;
                    padding-right: 30px;
                    text-transform: uppercase;
                    padding: 10px 30px 8px;
                  }
                  &.active {
                    a {
                      color: $red;
                    }                
                  }    
                }                
              }              
            }
          }
        }        
      }
    }
  }
  @media(max-width: 767px) {
    border-bottom: 1px solid $black;
  }
}

// specifically home page tweaks
.home-page-header:not(.nav-sticky) {
  #navbar {
    .nav.navbar-nav#menu-header-menu {
      &#menu-header-menu {
        > li {
          > a {
            font-size: 18px;
            @media(max-width: 767px) {
              font-size: 16px;
              letter-spacing: .5px;
            }
          }
          &.dropdown {
            &.open {
              > a {
                background-color: rgba(0,0,0,.15)!important;
                color: $black !important;
                @media(max-width: 767px) {
                  background-color: rgba(0, 0, 0, 0.4) !important;
                  color: $white !important;
                }
              }
            }
            .dropdown-menu {
              background-color: rgba(0,0,0,.15)!important;
              > li {
                a {
                  color: $white;
                  &:hover {
                    color: $black;
                  }
                  @media(max-width: 767px) {
                    color: #f5f5f5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.navbar.primary-header.nav-sticky {
  border-bottom: none;
}
