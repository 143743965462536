// buttons

.btn {
  display: block;
  border: 1px solid $red;
  background: $white;
  color: $black;
  font-size: 14px;
  width: 100%;
  width: auto;
  float: none;
  clear: both;
  margin: 10px auto;
  padding: 6px 20px;
  transition: all .2s ease;

  &:hover, &:focus, &:active {
    cursor: pointer;
    border: 1px solid $white;
    background: $red;
    color: $white;
  }

  &.btn-inline {
    display: inline-block;
  }

  &.btn-right {
    float: right;
    width: auto;
    margin: 10px 0 0 auto;
    margin-top: auto;
  }

  &.btn-left {
    float: left;
    width: auto;
    margin: 10px 0 0 auto;
    margin-top: auto;
  }

  &.btn-inline-right {
    float: none;
    display: inline-block;
    width: auto;
    margin: 10px 0;
  }

  &.btn-full-width {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
  }

  // variants
  &.btn-hollow {
    display: block;
    background: transparent;
    color: $white;
    border-color: $white;

    &:hover, &:focus, &:active {
      cursor: pointer;
      border: 1px solid $red;
      background: $red;
      color: $white;
    }
  }
  &.btn-red {
    background: $red;
    color: $white;
    &:hover, &:focus, &:active {
      cursor: pointer;
      border: 1px solid $red;
      background: transparent;
      color: $red;
    }
  }
}
