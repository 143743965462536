// util classes

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hidden-mobile {
  @media(max-width: 991px) {
    display: none;
  }
}
hr {
  display: block;
  clear: both;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid #cecdcb;
  width: 100%;
  flex-basis: 100%;
  &.dark {
    border-top-color: #333;
  }
  &.light {
    border-top-color: #f4f4f4;
  }
  &.red {
    border-top-color: $red;
  }
}
// po.st buttons
.pw-wrapper {
  display: block;
  width: 100%;
  margin: 20px auto 0;
  text-align: center;
}
.pw-widget, .pw-server-widget {
  margin: 5px auto;
  text-align: center;
}
